<template>
  <div class="uk-form-horizontal">
    <div
      class="uk-child-width-expand@s uk-grid-small"
      uk-grid
    >
      <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
        Package Name
      </div>
      <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
        <input 
          v-model="setting.package_name"
          type="text"  
          class="uk-input"
          disabled
        >
      </div>
    </div>
    <div
      class="filter-margin uk-child-width-expand@s uk-grid-small"
      uk-grid
    >
      <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
        Version Code
      </div>
      <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
        <input 
          v-model="setting.version_code"
          type="text"  
          class="uk-input"
          disabled
        >
      </div>
    </div>
    <div
      class="filter-margin uk-child-width-expand@s uk-grid-small"
      uk-grid
    >
      <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
        Force Update
      </div>
      <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
        <div class="uk-grid">
          <div class="uk-width-1-3">
            <input
              v-model="setting.force_update"
              class="switcher"
              type="checkbox"
              disabled
            >
          </div>
          <div class="uk-width-2-3 status-margin">
            <span
              v-if="setting.force_update"
              class="uk-text-success label-unbackground"
            >Ya</span>
            <span
              v-else
              class="uk-text-danger label-unbackground"
            >Tidak</span>
          </div>
        </div>
      </div>
    </div>
    <div
      class="filter-margin uk-child-width-expand@s uk-grid-small uk-text-right"
      uk-grid
    >
      <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-3@m uk-width-1-3@l uk-width-1-3@xl uk-margin-auto-left">
        <button
          class="uk-button uk-button-primary uk-width-expand uk-width-auto@s"
          type="button"
          :disabled="is_loading"
          @click="toEdit(setting.id)"
        >
          <div
            v-if="is_loading"
            uk-spinner
            class="button-spinner"
          />
          <img
            v-else
            v-lazy="`${images}/icon/edit.svg`"
            alt=""
            class="uk-margin-small-right"
          >
          Ubah
        </button>
      </div>     
    </div>
  </div>
</template>

<script>
import { PREFIX_IMAGE } from '@/utils/constant'
import { mapActions, mapGetters } from 'vuex'
export default {
  data() {
    return {
      images: PREFIX_IMAGE,
      testing: "testing",
      is_loading: true
    }
  },
  computed : {
    ...mapGetters({
      setting: 'setting/setting'
    })
  },
  async mounted() {
    await this.getSetting()
    this.is_loading = false
  },
  methods: {
    ...mapActions({
      getSetting: 'setting/getSetting'
    }),
    toEdit() {
      this.$router.push(`/admin/settings/edit`)
    }
  }
}
</script>

<style scoped>
.button-margin {
  margin-top: 30px;
}
.status-margin {
  padding-left: 5px !important;
  padding-top: 10px;
}
</style>

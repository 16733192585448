<template>
  <div class="uk-margin-top">
    <div class="uk-card uk-card-default uk-padding-small kandang-card container-size">
      <SettingUser />
    </div>
  </div>
</template>
<script>
import SettingUser from './setting.vue'
export default {
  components: {
    SettingUser
  }
}
</script>
<style scoped>
.container-size {
  height: 100%;
  width: 100%;
}

@media screen and (min-width: 768px) {
  .container-size {
    height: 100%;
    width: 550px;
  }
}
</style>
